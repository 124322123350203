function formattedDate(value) {
  const date = moment(value)
  const now = moment()

  const days = now.diff(date, 'days')

  if (days === 0) {
    return date.format('h:mm A')
  }

  return date.format('h:mm A MM/DD/YYYY')
}

function calculatePMHoursPerWeek() {
  const budget = $('#id_budget').val()
  const startDate = moment($('.start-date > input').val())
  const endDate = moment($('.end-date > input').val())

  if (budget == '' || !startDate.isValid() || !endDate.isValid()) {
    $('label[for=id_budget]').text('Budget')
    return
  }

  const lengthMonths = endDate.diff(startDate, 'months', true)
  const monthlyBudget = budget / lengthMonths
  const pmHours = ((monthlyBudget * 0.2) / 80 / (52 / 12)).toFixed(0)

  $('label[for=id_budget]').text('Budget (around ' + pmHours + ' PM hours per week)')
}

function setProjectStartDate(){
  $('.start-date > input').datepicker('setEndDate', $('.end-date > input').val())

  $('.start-date > input').on('changeDate', (selected) => {
    // set end date based on selected start date
    const startDate = new Date(selected.date.valueOf())

    // on update, remove invalid css if value is set
    if (startDate) {
      $('#id_startDate').removeClass('is-invalid')
    }

    // check required fields for status Estimating before enabling submit buttons
    if (startDate && $('#id_statusId').val() == STATUS_ID_ESTIMATING && $('#id_durationWeeks').val() && isBudgetSet()) {
      $('.btn').removeClass('disabled')
    }

    const durationWeeks = $('#id_durationWeeks').val() ? $('#id_durationWeeks').val() : 0
    const endDate = moment(startDate, 'MM/DD/YYYY')
      .add(parseInt(durationWeeks), 'weeks')
      .format('MM/DD/YYYY')
    $('#id_endDate').val(endDate)

    calculatePMHoursPerWeek()
  })
}

function setProjectEndDate(){
  $('#id_durationWeeks').on('change', () => {
    // on update, remove invalid css if value is set
    if ($('#id_durationWeeks').val()) {
      $('#id_durationWeeks').removeClass('is-invalid')
    }

    // check required fields before enabling submit buttons
    if ($('#id_statusId').val() != STATUS_ID_ESTIMATING && $('#id_startDate').val() && $('#id_durationWeeks').val()) {
      $('.btn').removeClass('disabled')
    } 
    if ( $('#id_durationWeeks').val() && $('#id_statusId').val() == STATUS_ID_ESTIMATING && $('#id_startDate').val() && isBudgetSet()) {
      $('.btn').removeClass('disabled')
    }

    // set end date based on selected duration in Weeks
    const durationWeeks = $('#id_durationWeeks').val() ? $('#id_durationWeeks').val() : 0
    const startDate = $('#id_startDate').val()
    if (startDate) {
      const endDate = moment(startDate, 'MM/DD/YYYY')
        .add(parseInt(durationWeeks), 'weeks')
        .format('MM/DD/YYYY')
      $('#id_endDate').val(endDate)
    }
    calculatePMHoursPerWeek()
  })
}

function pageOpportunityForm($form, initialValue) {
  $('.page-opportunity form :input').on('change input', () => {
    const currentValue = $form.serialize()

    if (currentValue !== initialValue && !$form.hasClass('modified')) {
      $form.addClass('modified')
    }

    if (currentValue === initialValue && $form.hasClass('modified')) {
      $form.removeClass('modified')
    }
  })
}

$(document).ready(() => {
  $('.formatted-number-input').each(handleAddCommas)
  $('.formatted-number-input').keyup(handleAddCommas)
  $("input[type=checkbox]").removeClass('form-control')
  $("#edit_project_form input[type=checkbox]").removeClass('form-control').removeClass('input-with-feedback')
  $("#convert input[type=checkbox]").removeClass('form-control').removeClass('input-with-feedback')

  $("input[type=checkbox]").removeClass('form-control')
  $("#edit_project_form input[type=checkbox]").removeClass('form-control').removeClass('input-with-feedback')
  $("#convert input[type=checkbox]").removeClass('form-control').removeClass('input-with-feedback')

  calculatePMHoursPerWeek()

  $('.skill-item__remove-button').click(function () {
    $(this).closest('li').remove()
  })

  $('.remove-developer-button').click((event) => {
    event.stopPropagation()
  })

  $('.page-opportunity .slider').slider({
    formatter: (value) => (value > 0 ? `${value * 10}%` : 'No idea'),
  })

  autosize($('textarea'))

  const STATUS_ID_ESTIMATING = '3'

  setProjectStartDate()

  setProjectEndDate()

  $('.end-date > input').on('changeDate', (selected) => {
    const endDate = new Date(selected.date.valueOf())
    $('.start-date > input').datepicker('setEndDate', endDate)
    calculatePMHoursPerWeek()
  })

  $('#id_budget').on('change', function () {
    calculatePMHoursPerWeek()
  })
  

  $('time').each(function () {
    $(this).text(formattedDate($(this).attr('datetime')))
  })

  // check if edit opportunity form was modified
  const $form = $('.page-opportunity form')
  const initialValue = $form.serialize()

  pageOpportunityForm($form, initialValue)

  $('#edit-skills-button').on('click', () => {
    // if changed, show modal. stop link from navigating
    if ($form.hasClass('modified')) {
      $('#projectWarningsModal').modal('show')
      return false
    }
    return true
  })

  // update probability tooltip value: ex => 10%
  const probabilitySlider = $('.probability-slider.has-tooltip')
  probabilitySlider.on('input', () => {
    const value = `${probabilitySlider.val()}%`
    probabilitySlider.attr('data-bs-original-title', value).tooltip('show')
  })

  // enable only the invite user check box if create repo checkbox is clicked
  const createRepoCheckBox = $('input[id=githubCreateProject]')
  $('#githubInviteUsers').attr('disabled','disabled');

  createRepoCheckBox.on('change', () => {
     if (createRepoCheckBox.is(':checked')) {
        $('#githubInviteUsers').removeAttr('disabled');
      } else {
        $('#githubInviteUsers').attr('disabled','disabled');
        $('#githubInviteUsers').prop('checked', false);
      }
  })

  const budgetRangeCheckbox = $('input[name=hasBudgetRange]')
  const budgetRangeLow = $('input[name=budgetRangeLow]')
  const budgetRangeHigh = $('input[name=budgetRangeHigh]')


  // initial renders
  if (budgetRangeCheckbox.is(':checked')) {
    $('.budget').hide()
    $('.budget-range').show()
    $('#id_budgetRangeHigh').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');
    $('#id_budgetRangeLow').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');
  } else {
    $('.budget-range').hide()
    $('.budget').show()
    $('#id_budget').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');

  }

  // on change -> have to manually set value for checkbox
  budgetRangeCheckbox.on('change', () => {
    if (budgetRangeCheckbox.is(':checked')) {
      $('.budget').hide()
      $('.budget-range').show()
      $('#id_budgetRangeHigh').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');
      $('#id_budgetRangeLow').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');   
    } else {
      budgetRangeCheckbox.val(false)
      $('.budget-range').hide()
      $('.budget').show()
      $('#id_budget').closest('.input-group').find('.input-group-prepend').addClass('input-group-sm');
    
    }
  })

  if (!budgetRangeLow.val() || !budgetRangeHigh.val()) {
    budgetRangeCheckbox.prop('checked', false)
    $('.budget-range').hide()
  }

  $('#id_budgetRangeLow').on('change', function () {
    if (budgetRangeLow.val()) {
      budgetRangeLow.removeClass('is-invalid')
    }
    if (budgetRangeLow.val() && budgetRangeHigh.val()) {
      $('.btn').removeClass('disabled')
    }
  })

  $('#id_budgetRangeHigh').on('change', function () {
    if (budgetRangeHigh.val()) {
      budgetRangeHigh.removeClass('is-invalid')
    }
    if (budgetRangeLow.val() && budgetRangeHigh.val()) {
      $('.btn').removeClass('disabled')
    }
  })

  $('#id_budget').on('change', function () {
    if ($('#id_budget').val()) {
      $('#id_budget').removeClass('is-invalid')
    }
    if ($('#id_statusId').val() == STATUS_ID_ESTIMATING && $('#id_startDate').val() && $('#id_durationWeeks').val() && $('#id_budget').val()) {
      $('.btn').removeClass('disabled')
    }
  })

  function isBudgetSet() {
    if (budgetRangeCheckbox.is(':checked')) {
      return budgetRangeLow.val() && budgetRangeHigh.val()
    } else {
      return $('#id_budget').val()
    }
  }

  // Manually add error messages for dynamically required fields
  $('#id_budget').after('<div class="invalid-feedback">Budget is required</div>')
  $('#id_startDate').after('<div class="invalid-feedback">Start date is required</div>')
  $('#id_endDate').after('<div class="invalid-feedback">End date is required</div>')
  $('#id_durationWeeks').after('<div class="invalid-feedback">Duration in Weeks is required</div>')
  budgetRangeLow.after('<div class="invalid-feedback">  Budget Range (low) is required</div>')
  budgetRangeHigh.after('<div class="invalid-feedback"> Budget Range (high) is required </div>')

  function showErrorMessage(errorMessage) {
    $('.messages').empty()
    $('.messages').append(
      '<div class="container-fluid mt-2">' +
          '<div class="row g-0 alert alert-danger alert-dismissable fade show" role="alert">' +
            '<div class="col col-lg-1 d-flex align-items-center justify-content-center">' +
              '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF6E52" class="avatar avatar-rounded-circle bi bi-check-circle-fill" viewBox="0 0 16 16">'+
                '<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>'+
              '</svg>' +
            '</div>' +
            '<div class="col align-self-center">' +
              errorMessage +
            '</div>' +
            '<div class="col col-lg-1 d-flex align-items-center justify-content-end">' +
              '<i class="bi bi-x" data-bs-dismiss="alert" aria-label="Close"></i>' +
            '</div>' +
          '</div>' +
      '</div>'

    )
  }

  // Intercept form submit if there is a required field error since we can't
  // change our server side form properties on the fly
  $('#edit_project_form').on('submit', function (e) {
    const isMissingClientFields =
    $('#id_clientId').val() == '' ||
    ($('#id_clientContactId').val() == '')

    const isMissingEstimatingFields =
      $('#id_statusId').val() == STATUS_ID_ESTIMATING &&
      ($('#id_startDate').val() == '' ||
        $('#id_endDate').val() == '' ||
       !isBudgetSet())
    const isMissingBudgetFields =
      budgetRangeCheckbox.is(':checked') &&
      (budgetRangeLow.val() == '' || budgetRangeHigh.val() == '')
    const isMissingProjectDuration = 
      $('#id_startDate').val() &&
      $('#id_durationWeeks').val() == ''
    
    if (isMissingEstimatingFields || isMissingBudgetFields || isMissingProjectDuration || isMissingClientFields) {
      if (isMissingEstimatingFields) {
        if (budgetRangeCheckbox.is(':checked')) {
          if (budgetRangeLow.val() == '') {
            budgetRangeLow.addClass("is-invalid")
          }
          if (budgetRangeHigh.val() == '') {
            budgetRangeHigh.addClass("is-invalid")
          }
        } else if ($('#id_budget').val() == '') {
          $('#id_budget').addClass("is-invalid")
        }
        
        if ($('#id_startDate').val() == '') {
          $('#id_startDate').addClass("is-invalid") 
        }
        if ($('#id_durationWeeks').val() == '') {
          $('#id_durationWeeks').addClass("is-invalid")
        }
      } else if (isMissingBudgetFields) {
        if (budgetRangeLow.val() == '') {
          budgetRangeLow.addClass("is-invalid")
        }
        if (budgetRangeHigh.val() == '') {
          budgetRangeHigh.addClass("is-invalid")
        }
      } else if (isMissingProjectDuration) {
        $("#id_durationWeeks").addClass("is-invalid")
      } else if (isMissingClientFields) {
        $('#id_clientId').addClass("is-invalid")
        $('#id_clientContactId').addClass("is-invalid")
      }

      e.preventDefault()

      const errorMessage = 'Oops! You missed a spot!'
      showErrorMessage(errorMessage)

      // Find the first invalid input and its parent accordion section
      const $invalidInput = $('.is-invalid').first()
      const $accordionSection = $invalidInput.closest('.accordion-item')

      // Collapse the accordion section
      if ($accordionSection.length) {
        const $accordionButton = $accordionSection.find('.accordion-button')
        if ($accordionButton.length && $accordionButton.hasClass('collapsed')) {
          $accordionButton.click()
        }
      }

      $('html, body').animate(
        {
          scrollTop: $('.is-invalid').offset().top - 140,
        },
        500
      )
    }
  })

  function addCommas(string) {
    return parseFloat(string.replace(/[^0-9.]+/g, '') || 0).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
  }

  function handleAddCommas() {
    const value = $(this).val()
    if (value) {
      $(this).val(addCommas(value))
    }
  }

  $('#deleteNotesModal').on('show.bs.modal', function (event) {
    var modal = $(this)
    var deleteButton = $(event.relatedTarget)
    var noteItem = deleteButton.closest('.note-item').data('note-item')

    modal.data('note-item', noteItem)
    modal.find('.note-id').attr('value', noteItem.id)
    modal.find('.opportunity-note-id').attr('value', noteItem.opportunityId)
  })

  $('.chosen-select').chosen()
  $('.chosen-select').removeClass('hide-until-loaded')

  $(".datepicker").attr("readonly", false);

  $('#editActionItemModal').on('show.bs.modal', function (event) {
    var modal = $(this)
    var editButton = $(event.relatedTarget)
    var actionItem = editButton.closest('.action-item').data('action-item')

    modal.data('actionItem', actionItem)
    modal.find('.actionItem-id').attr('value', actionItem.id)
    modal.find('#id_actionItem').attr('value', actionItem.actionItem)
    modal.find('#id_dueDate').attr('value', actionItem.dueDate ? moment.utc(actionItem.dueDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('MM/DD/YYYY') : actionItem.dueDate)
  })

  $('#deleteActionItemModal').on('show.bs.modal', function (event) {
    var modal = $(this)
    var deleteButton = $(event.relatedTarget)
    var actionItem = deleteButton.closest('.action-item').data('action-item')

    modal.data('actionItem', actionItem)
    modal.find('.actionItem-id').attr('value', actionItem.id)
  })

  $('.accordion-button').on('click', function () {
    const $icon = $(this).find('.accordion-icon');
    if ($(this).hasClass('collapsed')) {
      $icon.removeClass('bi-chevron-down').addClass('bi-chevron-right');
    } else {
      $icon.removeClass('bi-chevron-right').addClass('bi-chevron-down');
    }
  });
})
