window.createProjectWonChart = function createProjectWonChart(data) {
  const wonChart = new Chart('wonChart', {
    type: 'bar',
    data,
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              precision: 1,
              max: 10,
            },
          },
        ],
      },
      legend: {
        position: 'bottom',
      },
      tooltips: {
        callbacks: {
          label(tooltipItem) {
            const group = data.datasets[tooltipItem.datasetIndex]
            const names = group.opportunities[tooltipItem.index].map(
              (opportunity) => opportunity.name
            )

            return names
          },
        },
      },
      onClick: function (e) {
        let firstPoint = wonChart.getElementAtEvent(e)[0]

        if (firstPoint) {
          let ids = wonChart.data.datasets[firstPoint._datasetIndex].opportunities[
            firstPoint._index
          ].map((opportunity) => opportunity.id)

          window.location.href = '/projects/all?filter=report&ids=' + ids.join()
        }
      },
    },
  })

  return wonChart
}
