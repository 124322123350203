import Vue from 'vue';

const app = new Vue({
  el: '.page-opportunity',
  data: {
    sendProposal: {
      email: '',
      link: '',
    },
  },
  beforeMount() {
    if (this.$el.querySelector('[ref="clientContactEmail"]')) {
      this.sendProposal.email = this.$el.querySelector('[ref="clientContactEmail"]').value;
    }
  },
  computed: {
    isSendButtonEnabled() {
      const recipients = this.sendProposal.email.split(',');;
      let isValidEmail = true;
      if (recipients.length > 0) {
        recipients.forEach((recipient) => {
          if (!this.validateEmail(recipient.trim())) {
            isValidEmail = false;
          }
        });
      }
      const isValidUrl = this.sendProposal.link.length > 0 && this.validateLink(this.sendProposal.link);
      return isValidEmail && isValidUrl;
    },
  },
  methods: {
    validateEmail(value) {
      return /^\S+@\S+\.\S+$/.test(value);
    },
    validateLink(value) {
      return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value);
    },
  },
})