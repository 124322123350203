function hasModernFeatures() {
  const div = document.createElement('div')
  return (
    ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
    'FormData' in window &&
    'FileReader' in window
  )
}

function updatePreview(files) {
  const $container = $('.uploader__preview')

  $container.empty()

  for (let i = 0; i < files.length; i += 1) {
    $container.append($(`<div>${files[i].name}</div>`))
  }
}

$(document).ready(() => {
  const $form = $('.uploader')
  const $input = $('.uploader__file')

  $input.change(function () {
    console.log('Change')
    updatePreview(this.files)
  })

  if (hasModernFeatures()) {
    $form.addClass('uploader--modern')

    $form
      .on('dragover dragenter', () => $form.addClass('uploader--dragover'))
      .on('dragleave dragend drop', () => $form.removeClass('uploader--dragover'))
  }

  $form.on('submit', () => {
    $form.addClass('uploader--uploading')
  })
})
