import Vue from 'vue';
const axios = require('axios');
const {
  MAX_SKILL_LEVEL,
  SKILL_LEVEL_DESCRIPTIONS,
  skillLevelDescription,
  skillLevelBadgeClass
} = require('../../src/skillLevels');

Vue.component('alert-message', {
  props: ['message', 'state'],
  data: function () {
    return {
      successState: 'success',
      successClass: 'row g-0 alert alert-success alert-dismissable fade show',
      errorClass: 'row g-0 alert alert-danger alert-dismissable fade show',
      successIcon: '#99E0B6',
      errorIcon: '#FF6E52'
    }
  },
  template: '<div :class="[state==successState ? successClass : errorClass]" role="alert">' +
              '<div class="col col-lg-1 d-flex align-items-center justify-content-center">'+
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="[state==successState ? successIcon : errorIcon]" class="avatar avatar-rounded-circle bi bi-check-circle-fill" viewBox="0 0 16 16">'+
                  '<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>'+
                '</svg>' +
              '</div>'+
              '<div class="col align-self-center">'+
                '{{message}}'+
              '</div>'+
              '<div class="col col-lg-1 d-flex align-items-center justify-content-end">'+
                '<i class="bi bi-x" data-bs-dismiss="alert" aria-label="Close"></i>' +
              '</div>' +
            '</div>'
})


function getProjectWeekNumber(startDate, specificDate) {
    // Ensure dates are Date objects
    startDate = new Date(startDate);
    specificDate = new Date(specificDate);

    // Adjust start date to the Monday of its week
    const day = startDate.getDay();
    const diff = startDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    startDate.setDate(diff);

    // Calculate the difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((specificDate - startDate) / oneDay));

    // Calculate the week number
    const weekNumber = Math.floor(diffDays / 7) + 1;

    return weekNumber;
}

const app = new Vue({
  el: '.page-team',
  delimiters: ['${', '}'], // change the delimiters since vue and nunjucks have the same syntax
  data: {
    selectedSkill: null,
    filterBy: null,
    sortBy: null,
    selectedSkills: [],
    availableSkills: [],
    skillIds: [],
    errors: {},
    opportunityId: null,
    action: null,
    alertMessages: [],
    availableUsersWithForecastedHours: [],
    projectDurationInWeeks: 0,
    team: [],
    isNew: 0,
    projectStartDate: null,
    projectEndDate: null,
    newStartWeek: 0,
    newEndWeek: 0,
    newProjectStartDate: null,
    newProjectEndDate: null,
    totalWeeks: [],
    MAX_SKILL_LEVEL,
    SKILL_LEVEL_DESCRIPTIONS,
    skillLevelDescription,
    skillLevelBadgeClass,
    domain: (process.env.NODE_ENV === 'production') ? 'lelander.com': 'dev.lelndr.co',
    existingDevelopers: [],
    filterOptions: [
      {label: 'Any', minValue: 1},
      {label: '5+ hours', minValue: 5},
      {label: '10+ Hours', minValue: 10},
      {label: '20+ Hours', minValue: 20},
      {label: '30+ Hours', minValue: 30},
      {label: '40+ Hours', minValue: 40}
    ]
  },
  beforeMount() {
    if (this.$el.querySelector('[ref="availableSkills"]')) {
      this.availableSkills = JSON.parse(this.$el.querySelector('[ref="availableSkills"]').value);
    }

    if (this.$el.querySelector('[ref="skillIds"]')) {
      this.skillIds = JSON.parse(this.$el.querySelector('[ref="skillIds"]').value);
    }

    if (this.$el.querySelector('[ref="opportunityId"]')) {
      this.opportunityId = this.$el.querySelector('[ref="opportunityId"]').value;
    }

    if (this.$el.querySelector('[ref="selectedSkills"]')) {
      this.selectedSkills = JSON.parse(this.$el.querySelector('[ref="selectedSkills"]').value);
    }

    if (this.$el.querySelector('[ref="availableUsersWithForecastedHours"]')) {
      this.availableUsersWithForecastedHours = JSON.parse(this.$el.querySelector('[ref="availableUsersWithForecastedHours"]').value);
    }

    if (this.$el.querySelector('[ref="projectDurationInWeeks"]')) {
      this.projectDurationInWeeks = this.$el.querySelector('[ref="projectDurationInWeeks"]').value;
    }

    if (this.$el.querySelector('[ref="existingDevelopers"]')) {
      const currentDevs = JSON.parse(this.$el.querySelector('[ref="existingDevelopers"]').value);
      this.existingDevelopers = currentDevs;
    }

    if (this.$el.querySelector('[ref="isNew"]')) {
      this.isNew = this.$el.querySelector('[ref="isNew"]').value;
    }

    if (this.$el.querySelector('[ref="startDate"]')) {
      this.projectStartDate = this.$el.querySelector('[ref="startDate"]').value;
    } else {
      this.projectStartDate = new Date().toISOString().slice(0, 10);
    }
    this.newProjectStartDate = this.projectStartDate;

    if (this.$el.querySelector('[ref="endDate"]')) {
      this.projectEndDate = this.$el.querySelector('[ref="endDate"]').value;
    } else {
      const today = new Date();
      let endOfWeek = new Date(today);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      this.projectEndDate = endOfWeek.toISOString().slice(0, 10);
    }
    this.newProjectEndDate = this.projectEndDate;
  },
  computed: {
    isSaveButtonEnabled () {
      return this.selectedSkills.length > 0 && Object.keys(this.errors).length === 0;
    },
    filteredAvailableSkills() {
      // Filter out selected skills from availableSkills and sort them by name
      return this.availableSkills.filter(skill => !this.skillIds.includes(skill.id)).sort((a, b) => a.name.localeCompare(b.name));
    },
    teamCapacity() {
      // Create a map to keep track of the remaining hours for each developer
      const remainingHours = new Map();

      // Create a set to keep track of the developers who have already been added to a skill
      const addedDevelopers = new Set();

      // Initialize the remaining hours for each developer
      this.teamMembers.forEach(member => remainingHours.set(member.name, member.forecastedHours.map(hour => hour.totalAvailableHours)));

      // Sort skills by required weekly hours in descending order
      const sortedSkills = [...this.selectedSkills].sort((a, b) => b.hours - a.hours);

      // loop through sorted skills and calculate the team capacity for each skill
      return sortedSkills.map(skill => {
        // Initialize remainingSkillHours as an array with the same length as the number of weeks
        let remainingSkillHours = Array(this.totalWeeks.length).fill(skill.hours);

        // sort members by skill level and total available hours
        const usersWithSkill = this.teamMembers
          .filter(member => member.skillLevels.some(skillLevel => skillLevel.skillId === skill.id))
          .sort((a, b) => {
            const aSkillLevel = a.skillLevels.find(skillLevel => skillLevel.skillId === skill.id).level;
            const bSkillLevel = b.skillLevels.find(skillLevel => skillLevel.skillId === skill.id).level;
            return bSkillLevel - aSkillLevel || b.totalAvailableHours - a.totalAvailableHours;
          });

        const groupedByWeek = usersWithSkill.reduce((acc, member) => {
          const skillLevel = member.skillLevels.find(skillLevel => skillLevel.skillId === skill.id);

          // Only add the user if they haven't been added yet and they have remaining hours and all hours required for the skills have not filled up yet.
          if (Object.values(remainingSkillHours).every(hours => hours !== 0) && skillLevel && remainingHours.get(member.name).some(hours => hours > 0) && !addedDevelopers.has(member.name)) {
            // Add the developer to the set of added developers to prevent them from being added to another skill
            addedDevelopers.add(member.name);

            member.forecastedHours.forEach(hour => {
              const weekIndex = acc.findIndex(week => week.weekNumber === hour.weekNumber);
              const allocatedHours = Math.min(hour.totalAvailableHours, remainingSkillHours[hour.weekNumber-1]);
              const user = {
                name: member.name,
                skillLevel: skillLevel ? skillLevel.level : null,
                requiredHoursPercent: ((allocatedHours / skill.hours) * 100).toFixed(0) + "%",
                totalAvailableHours: allocatedHours
              };

              if (weekIndex === -1) {
                acc.push({
                  weekNumber: hour.weekNumber,
                  mondayDate: hour.mondayDate,
                  sundayDate: hour.sundayDate,
                  users: [user]
                });
              } else {
                acc[weekIndex].users.push(user);
              }

              // Subtract the allocated hours from the developer's remaining hours and the skill's remaining hours
              const newHours = remainingHours.get(member.name).map((hours, index) => {
                // Only subtract the allocated hours from the current week
                if (index === hour.weekNumber) {
                  return hours - allocatedHours;
                }
                return hours;
              });
              remainingHours.set(member.name, newHours);
              remainingSkillHours[hour.weekNumber-1] -= allocatedHours;
            });
          }
          return acc;
        }, []);

        // Calculate user available hours percentage for each week
        groupedByWeek.forEach(week => {
          const users = week.users;
          users.sort((a, b) => parseFloat(a.requiredHoursPercent) - parseFloat(b.requiredHoursPercent));
          let totalPercent = 0;
          users.forEach(user => {
            totalPercent += parseFloat(user.requiredHoursPercent) / 100;
          });
          this.calculateUserProperties(users, totalPercent);
        });

        return {
          name: skill.name,
          id: skill.id,
          hours: skill.hours,
          weeklyForecast: groupedByWeek
        };
      });
    },
    teamMembers() {
      return this.team.concat(this.existingDevelopers);
    },
    sortedSkills() {
      return [...this.selectedSkills].sort((a, b) => b.hours-a.hours);
    },
    availableUsers() {
      this.newStartWeek = getProjectWeekNumber(this.projectStartDate, this.newProjectStartDate)
      this.newEndWeek = getProjectWeekNumber(this.projectStartDate, this.newProjectEndDate)

      let startWeek = 1;
      let endWeek = this.projectDurationInWeeks;

      if (this.newStartWeek >= 1 && this.newEndWeek >= 1 && this.newStartWeek <= this.newEndWeek) {
          startWeek = this.newStartWeek;
          endWeek = Math.min(this.newEndWeek, this.projectDurationInWeeks);
      }

      this.totalWeeks = Array.from({length: endWeek - startWeek + 1}, (_, i) => {
        let weekNumber = startWeek + i;
        return {
          number: weekNumber,
          days: this.getDatesFromWeekNumber(weekNumber-1)
        };
      });

      const teamMemberIds = this.teamMembers.map(member => member.id);
      let sortedSkills = [...this.selectedSkills].sort((a, b) => b.hours-a.hours).map(skill => skill.id);
      if (this.sortBy) {
        sortedSkills = sortedSkills.filter(id => id !== this.sortBy);
        sortedSkills.unshift(this.sortBy);
      }

      /** create a local copy of availableUsersWithForecastedHours and
       filter out the forecasted hours that are not within the selected dates for project duration and
       compute the total available hours per user for the selected dates **/
      const usersWithForecastedHours = this.availableUsersWithForecastedHours.map(user => {
        const weeklyHours = user.forecastedHours.filter(item => item.weekNumber >= this.newStartWeek && item.weekNumber <= this.newEndWeek)
        return {
          ...user,
          skillId: user.skillLevels.map(skillLevel => skillLevel.skillId),
          weeklyHours,
          totalAvailableHours: weeklyHours.reduce((total, item) => total + item.totalAvailableHours, 0)
        }
      });

      // sort and filter available users
      const sortedAvailableUsers = usersWithForecastedHours
        .filter(user => !teamMemberIds.includes(user.id) && user.averageAvailaleHours >= this.filterBy)
        .sort((a, b) => {
          const aSkillIndex = a.skillId.map(id => sortedSkills.indexOf(id)).sort()[0];
          const bSkillIndex = b.skillId.map(id => sortedSkills.indexOf(id)).sort()[0];
          return aSkillIndex-bSkillIndex;
        });

      return sortedAvailableUsers;
    },
    sortOptions() {
      return this.selectedSkills.map(skill => ({name: skill.name, id: skill.id}));
    },
    maxWeeks() {
      let max = 0;
      this.teamCapacity.forEach(skill => {
        max = Math.max(max, skill.weeklyForecast.length);
      });
      return new Array(max).fill(0);
    }
  },
  methods: {
    addSkill(skillId) {
      // Find the skill object in availableSkills that has the same id as selectedSkill
      const skill = this.availableSkills.find(skill => skill.id == skillId);

      // Check if this skill object already exists in selectedSkills
      const skillExistsInSelectedSkills = this.selectedSkills.some(selectedSkill => selectedSkill.id === skillId);

      // If it doesn't exist, add it to selectedSkills
      if (!skillExistsInSelectedSkills) {
        skill.hours = 20;
        this.selectedSkills.push(skill);

        // Update selected skill ids
        this.skillIds.push(skillId);
      }
      this.selectedSkill = null;
      this.getUpdatedList();
    },

    removeSkill(skillId) {
      // check if this skill exists on selected skills
      const skill = this.selectedSkills.find(selectedSkill => selectedSkill.id == skillId);
      if (skill) {
        // remove skill from selectedSkills
        this.selectedSkills = this.selectedSkills.filter(skill => skill.id !== skillId);

        // remove skill id from skillIds
        this.skillIds = this.skillIds.filter(id => id !== skillId);
        if (this.skillIds.length === 0) {
          this.availableUsersWithForecastedHours = [];
        } else {
          this.getUpdatedList();
        }
      }
    },

    updateHours(skillId, hours) {
      // find skill in selectedSkills
      const skill = this.selectedSkills.find(skill => skill.id === skillId);
      // validate hours
      if (hours <= 0) {
          this.$set(this.errors, skillId, 'Hours must be a positive number');
      } else {
        // update hours
        if (skill) {
            skill.hours = hours;
        }
        this.$delete(this.errors, skillId);
        this.getUpdatedList();
      }
    },
    onSubmit() {
      // check for validation error before submission
      if (Object.keys(this.errors).length === 0) {

        const developerIds = this.teamMembers.map(developer => developer.id);
        const data = {
          action: this.action,
          skills: this.selectedSkills,
          developers: developerIds,
          startDate: this.newProjectStartDate,
          durationWeeks: this.totalWeeks.length
        };

        const url = '/projects/' + this.opportunityId + '/team'
        const config = {
          header: {'Content-Type': 'application/json'},
        }

        axios
        .post(url, data, config)
        .then((res) => {
          if (!!Number(this.isNew)) {
            window.location.href = `/projects/${this.opportunityId}`
          } else {
            this.alertMessages.push({state: "success", message: "Skills and developers saved"})
            this.selectedSkill = null
            this.error = null
          }
        })
        .catch((error) => {})
      }
    },
    addToTeam(member) {
      this.team.push(member);
    },
    removeFromTeam(member) {
      const teamIndex = this.team.findIndex(m => m.id === member.id);
      if (teamIndex > -1) {
        this.team.splice(teamIndex, 1);
      }

      const existingDevelopersIndex = this.existingDevelopers.findIndex(m => m.id === member.id);
      if (existingDevelopersIndex > -1) {
        this.existingDevelopers.splice(existingDevelopersIndex, 1);
      }
      event.stopPropagation();
    },
    getSkillLevelProgressBarClass(skillLevel) {
      if (skillLevel.level <= 2) {
        return `bg-danger`
      } else if (skillLevel.level <= 4) {
        return `bg-warning`
      } else if (skillLevel.level <= 6) {
        return `bg-success`
      } else if (skillLevel.level <= 8) {
        return `bg-info`
      } else if (skillLevel.level <= 10) {
        return `bg-primary`
      } else {
        return ''
      }
    },
    initials(name) {
      return name.split(' ').map(word => word[0]).join('').toUpperCase();
    },
    capitalizeFirstLetter(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getUpdatedList() {
      // check for validation error before submission
      if (Object.keys(this.errors).length === 0) {
        const data = {
          projectId: this.opportunityId,
          skills: this.skillIds
        };
        const url = '/api/users'
        const config = {
          header: {'Content-Type': 'application/json'},
        }
        axios
        .post(url, data, config)
        .then((res) => {
          this.availableUsersWithForecastedHours = res.data;
          this.selectedSkill = null
          this.error = null
        })
        .catch((error) => {})
      }
    },
    getUsersForWeek(weeklyForecast, weekIndex) {
      if (Array.isArray(weeklyForecast) && weeklyForecast.length > weekIndex) {
        return weeklyForecast[weekIndex].users;
      } else {
        return [];
      }
    },
    calculateBottom(users, currentIndex) {
      let bottom = 0;
      for (let i = 0; i < currentIndex; i++) {
        bottom += parseFloat(users[i].height) / 100;
      }
      return `${bottom * 100}%`;
    },
    getColorFromSkillLevel(skillLevel) {
      if (skillLevel <= 2) {
        return `#FF3366`
      } else if (skillLevel <= 4) {
        return `#FF8C00`
      } else if (skillLevel <= 6) {
        return `#00CC88`
      } else if (skillLevel <= 8) {
        return `#00D4FF`
      } else if (skillLevel <= 10) {
        return `#5C60F5`
      } else {
        return ''
      }
    },
    calculateUserProperties(users, totalPercent) {
      // Sort users by skill level in descending order
      users.sort((a, b) => b.skillLevel - a.skillLevel);

      // Scenario 3: If all users have the same requiredHoursPercent, only the user with the highest skill level should occupy the column
      if (users.length > 1) {
        const allSamePercent = users.every(user => user.requiredHoursPercent === users[0].requiredHoursPercent);
        if (allSamePercent) {
          users[0].height = '100%';
          users[0].color = this.getColorFromSkillLevel(users[0].skillLevel);
          for (let i = 1; i < users.length; i++) {
            users[i].height = '0%'; // Other users are hidden
            users[i].color = this.getColorFromSkillLevel(users[i].skillLevel);
          }
          return;
        }
      }

      // Calculate cumulative percent for stacking users on top of each other
      let cumulativePercent = 0;

      users.forEach((user, index) => {
        const percent = parseFloat(user.requiredHoursPercent) / 100;
        let height;

        // Scenario 1: If totalPercent is less than or equal to 1, stack users on top of each other
        if (totalPercent <= 1) {
          height = `${percent * 100}%`;
          cumulativePercent += percent;
        } 
        // Scenario 2: If totalPercent is greater than 1, distribute height among users
        else {
          if (cumulativePercent + percent <= 1) {
            height = `${percent * 100}%`;
            cumulativePercent += percent;
          } else {
            height = `${(1 - cumulativePercent) * 100}%`;
            cumulativePercent = 1; // No more height available after this user
          }
        }

        // Set color based on user's color property
        const color = this.getColorFromSkillLevel(user.skillLevel);

        // Update user properties
        user.height = height;
        user.color = color;
      });
    },
    getDatesFromWeekNumber(weekNumber) {
      const startDate = moment(this.projectStartDate);
      const monday = startDate.clone().add(weekNumber, 'weeks').startOf('isoWeek').format('MM/DD/YYYY');
      const sunday = startDate.clone().add(weekNumber, 'weeks').endOf('isoWeek').format('MM/DD/YYYY');
      return `${monday} to ${sunday}`;
    }
  }
})