$(document).ready(() => {
  $('.opportunities-table tbody tr').click(function () {
    const opportunityId = $(this).data('id')

    window.location = `/projects/${opportunityId}`
  })

  $('.print-opportunities').on('click', () => {
    window.print()
  })
})
