$(document).ready(() => {
  // Hide error messages initially
  $('#add-info-error, #edit-info-error').hide();

  // Initialize Quill editor
  const quill = new Quill('#editor-container', { theme: 'snow' });

  // Set initial content if available
  const initialContent = $('#id-note').val();
  if (initialContent) {
    quill.root.innerHTML = initialContent;
  }

  // Function to handle form submission
  const handleFormSubmission = (e, errorElement, fileRequired = false) => {
    const quillContent = quill.root.innerHTML;
    $('#id-note').val(quillContent);

    // Validate if content is empty and (optionally) if file is required
    const isQuillContentEmpty = !$.trim($('#id-note').val()).length;
    const isFileEmpty = fileRequired && $('#file').get(0).files.length === 0;
    console.log(isQuillContentEmpty, isFileEmpty);
    if (isQuillContentEmpty && isFileEmpty) {
      $(errorElement).show();
      e.preventDefault();
    }
  };

  // Bind event handlers for both 'save-info' and 'edit-info' buttons
  $('#save-info').on('click', function (e) {
    handleFormSubmission(e, '#add-info-error', true); // Requires file upload validation
  });

  $('#edit-info').on('click', function (e) {
    handleFormSubmission(e, '#edit-info-error'); // No file upload validation
  });
});
